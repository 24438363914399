@charset "utf-8";

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/NotoSansKR-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/NotoSansKR-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/NotoSansKR-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/NotoSansKR-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumSquare';
  font-weight: 300;
  src: url('../fonts/NanumSquareL.eot'), url('../fonts/NanumSquareL.woff') format('woff'),
    url('../fonts/NanumSquareL.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumSquare';
  font-weight: normal;
  src: url('../fonts/NanumSquareR.eot'), url('../fonts/NanumSquareR.woff') format('woff'),
    url('../fonts/NanumSquareR.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumSquare';
  font-weight: bold;
  src: url('../fonts/NanumSquareB.eot'), url('../fonts/NanumSquareB.woff') format('woff'),
    url('../fonts/NanumSquareB.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumSquare';
  font-weight: 800;
  src: url('../fonts/NanumSquareEB.eot'), url('../fonts/NanumSquareEB.woff') format('woff'),
    url('../fonts/NanumSquareEB.ttf') format('truetype');
}
